.navigationmenu{
  align-items: center;
	background-color: #fff;
	border-radius: 20px;
	cursor: pointer;
	display: flex;
	font-size: 16px;
	padding: 15px 20px;
	user-select: none;
	width: 300px;
  margin:5px 0px
}
.currentmenu{
  align-items: center;
	background-color: #fff;
	border-radius: 20px;
	cursor: pointer;
	display: flex;
	font-size: 16px;
	padding: 15px 20px;
	user-select: none;
	width: 300px;
}